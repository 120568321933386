const styles = {
	mainContainer: "tw-canister tw-my-6 md:tw-mt-10 xl:tw-mt-16",
	companyListContainer: "tw-flex tw-w-full tw-flex-col tw-gap-4 sm:tw-gap-6",
	paginationContainer:
		"tw-flex tw-justify-between tw-gap-2 sm:tw-justify-normal sm:tw-mx-auto sm:tw-my-6",
	pageLink:
		"tw-hidden tw-text-[#4A5059] tw-text-center tw-bg-white tw-capitalize tw-pt-2.5 tw-pb-3 tw-px-4 tw-text-sm tw-font-medium tw-leading-6 tw-shadow-[0px_4px_24px_-4px_rgba(90,97,107,0.07),0px_2px_6px_-2px_rgba(90,97,107,0.07),0px_1px_3px_-1px_rgba(90,97,107,0.07),0px_0px_1px_0px_rgba(90,97,107,0.25)] tw-rounded-[12px] tw-border-[0.5px] tw-border-solid tw-border-[rgba(22,34,51,0.07)] sm:tw-inline-flex",
	previousLink: "!tw-inline-flex tw-mr-6",
	nextLink: "!tw-inline-flex tw-ml-6",
	activeLink: "!tw-bg-[#16A34A] !tw-text-white",
	disabledLink: "tw-opacity-50 tw-cursor-not-allowed",
};

export default styles;
