import { PageContext } from "../../components/CompanyList/PageContext";
import { AppProviders } from "../../components/UIComponents/AppProviders";
import { BaseCompanyList } from "../../components/UIComponents/BaseCompanyList";
import { MovingCompanies } from "./verticals/MovingCompanies";
import styles from "./verticals/pageLayoutStyles";

export const App = () => (
	<PageContext.Provider
		value={{ pageStyles: styles, companyComponent: MovingCompanies }}
	>
		<AppProviders>
			<BaseCompanyList />
		</AppProviders>
	</PageContext.Provider>
);
